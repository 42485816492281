@media only screen and (max-width: 759px) {
  .btn-operation {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 760px) {
  .btn-operation {
    margin-bottom: 10px;
  }
}

.uil-ellipsis-v {
  font-size: 20px;
}

.tiptable > .dropdown-toggle::after {
  display: none;
}

.tiptable > .dropdown-toggle.btn.btn-transparent {
  :hover {
    border: none;
  }
  :focus {
    border: none;
  }
  :active {
    border: none;
  }
}

.tiptable > .dropdown-toggle {
  padding: 0 !important;
  color: #004d59;
  height: 20px;
}

.tiptable > .dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.405);
  padding: 0 !important;
  box-shadow: 1px 2px 0.2rem rgba(0, 0, 0, 0.177);
}

.tipitem {
  color: rgb(129, 129, 129) !important;
  margin-top: 4px !important;
  font-size: 12px !important;
  padding: 4px 10px;
}

.tipitem:last-child {
  border-bottom: none;
}

.tipitem:hover {
  background: rgb(195, 195, 195) !important;
}

.tipitem:active {
  background: #fff !important;
}

.tipitemActive {
  color: rgb(0, 0, 0) !important;
}

.color-primary {
  color: #004d59;
}
