//
// reboot.scss
//

body {
  &.loading {
    visibility: hidden;
  }
}

button,
a {
  outline: none !important;
}

// Forms

label {
  font-weight: $font-weight-semibold;
  font-size: 12px;
}

// Address
address.address-lg {
  line-height: 24px;
}

b,
strong {
  font-weight: $font-weight-semi-bold;
}
