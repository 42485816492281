//
// Client.scss
//

.check-term {
  margin: 2em;
}

.check-term input[type='checkbox'] {
  width: 1.112em;
  height: 1.112em;
  margin-top: 0.194em;
  vertical-align: top;
  background-color: #727cf5;
  border-color: #727cf5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.check-term-label {
  margin-left: 1em;
}

.button-save-submit {
  margin-top: 1.25em;
}

.button-term {
  color: #6c757d !important;
  padding: 0 !important;
  text-decoration: underline;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-term:hover {
  color: #6c757d !important;
}

.not-display {
  display: none !important;
}

.spinner-loading {
  position: absolute;
  top: 49%;
  left: 50%;
}

.bg-drops-client {
  background-color: $color-lemon;
}
