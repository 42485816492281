//
// tables.scss
//

//Table centered (Custom)
.table-centered {
  th,
  td {
    vertical-align: middle !important;
  }
}

// Custom table components (Custom)
.table {
  color: #333333;
  font-weight: 600;
  font-size: 12px;
  --bs-table-striped-bg: #fff;
  --bs-table-hover-bg: #fff;

  .table-user {
    img {
      height: 30px;
      width: 30px;
    }
  }
  .action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
      color: $gray-700;
    }
  }
}

.table > :not(caption) > * > {
  th {
    padding: 0.65rem 0.15rem !important;
  }
}

.table-striped > tbody > tr {
  td {
    padding: 0.65rem 0.15rem !important;
  }
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  color: #333333;
}

.table-hover > tbody > tr:hover > * {
  color: #333333;
}

// Tables fluid
.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table-filter-search-col-empty {
  --bs-table-accent-bg: #fff !important;
  padding: 5px 0 !important;
}

.th-icon-order {
  div {
    display: inline;
    position: relative;
    button {
      margin: 0 3px !important;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0px;
      img {
        width: 1.3em;
      }
    }
    @media (max-width: 1380px) {
      div {
        position: relative;
        button {
          position: relative;
        }
      }
    }
  }
}

// table-alternate-colors

.table-alternate-colors > tbody {
  color: #8e9ca8;
}

.table-alternate-colors > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #f0f4f5;
  color: #8e9ca8;
}

.table-alternate-colors > tbody > tr:hover > * {
  --bs-table-accent-bg: #dddede;
  color: #768088;
}

.table-alternate-colors > tbody > tr:nth-of-type(odd):hover > * {
  --bs-table-accent-bg: #dddede;
  color: #768088;
}
