.input-wrapper {
  display: flex;
  flex-direction: column;
}

.input-wrapper p {
  margin: 0;
}

.input-wrapper-label {
  margin-bottom: 0.5rem !important;
  color: #333333;
  font-weight: 600;
  font-size: 12px;
}

.input-wrapper-div {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 33px;
  padding-left: 14px;

  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.input-wrapper label {
  color: #8e9ca8;

  cursor: pointer;
}

.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 33px;

  background-color: #8e9ca8;
}

#details-form-drops {
  label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  label.multiline {
    white-space: normal;
  }

  label.multiline::after {
    content: '...';
  }
}
