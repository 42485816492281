.point-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.point-checkbox input {
  width: 15px;
  height: 15px;
  border: 1px solid #004d59;
}

.point-checkbox label {
  color: #004d59;
  font-size: 12px;
  font-weight: 600;
}
