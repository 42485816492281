.container-order-cart {
  h4 {
    color: $color-lead;
  }
  .total-payable {
    font-size: 18px;
    color: $color-default-2;
    font-weight: $font-weight-semi-bold;
    margin: 0 0 16px 0;
    span {
      float: right;
    }
  }
  .selected-order {
    padding: 16px 0 0 0;
    border-top: 1px solid #e4e6ef;
    p:first-child {
      font-weight: $font-weight-semi-bold;
      color: $color-lead;
      margin-bottom: 4px;
    }
  }
}
