.container-payment {
  margin-bottom: 16px;
  .order-code {
    color: $color-dark-green;
    font-weight: $font-weight-bold;
  }
  .order-value {
    color: $color-default-2;
    font-weight: 500;
  }
  .information {
    color: $color-dark-green;
    font-size: 12px;
    font-weight: $font-weight-semi-bold;

    div {
      padding-top: 0.8rem;
    }

    span {
      font-size: var(--bs-body-font-size);
      font-weight: $font-weight-normal;
    }
  }
  .total-payable {
    font-size: 18px;
    color: $color-default-2;
    font-weight: $font-weight-semi-bold;
    margin: 0 0 16px 0;
    div {
      border-bottom: 1px solid #e4e6ef;
      padding-bottom: 16px;
      span {
        float: right;
      }
    }
  }
}
