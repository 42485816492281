.button-radio {
  width: 100%;
  margin: 0.5rem 0 1.5rem 0;
}

.submit-button {
  width: 100%;
}

.link-search-point {
  font-size: 11px;
  text-decoration: underline;
  margin-top: -3px;
  margin-left: 24px;
  cursor: pointer;
  color: #98a6ad;
  position: absolute;
}

.scenario2-quote-view {
  h5 {
    font-weight: 600;
    margin-bottom: 2px;
  }
  h6 {
    color: $gray-600;
    font-weight: 500;
    margin: 2px 0;
  }
}

.content-signature,
.content-image-voucher {
  border: 5px solid #d1f3f3;
  margin: 1.8rem 0 1.8em 0;
  padding: 10px 10px 25px 10px;
  max-width: 490px;

  img {
    width: 100%;
    height: 100%;
  }
  button {
    margin-top: -10px;
  }
  .btn-check:focus + .btn,
  .btn:focus {
    box-shadow: none;
  }
}
