.template-create-pudos {
  font-size: 12px;
  color: #004d59;
  font-weight: 600;
  margin-bottom: 2em;
}

.table-create-pudos {
  .table-striped > tbody > tr:nth-of-type(odd):has(td span.error-property) > * {
    --bs-table-accent-bg: #fddee5;
  }
  .table-striped > tbody > tr:has(td span.error-property) > * {
    --bs-table-accent-bg: #fddee5b3;
  }
  .error-property {
    color: #fa5c7c;
  }
}

.functions-container {
  margin: 0 0.8em 0 0.2em;
  display: flex;
  align-items: center;
  i {
    margin: 0 0.2em;
  }
}

.no-wrap-div {
  white-space: nowrap;
}
