.document-print-container {
  color: #000;
  height: 100vh;
  padding: 55px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px !important;
}

.document-print-container strong {
  font-size: 11px;
  padding-left: 3px;
}

.contend-address {
  padding-left: 3px;
}

.contend-address strong {
  padding-left: 0;
}

.document-print-container hr {
  color: #000;
}

.document-print-container .content {
  border: #000 2px solid;
}

.document-print-container .content table {
  width: 100%;
}

.document-print-container .content table th {
  color: #000;
}

.table-print {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 11px !important;
  color: #000;
}

.table-print th {
  border: #000 1px solid;
  padding-left: 3px;
}

.table-print td {
  border: #000 1px solid;
  padding-left: 3px;
}

.table-print .total {
  background: #f5efef;
}

.table-print .total .table-content {
  text-align: right;
  padding-right: 3px;
}

.table-content {
  width: 55%;
}

.content p {
  margin: 3px;
}

.description-seller {
  margin-left: 3px;
}

.hr-signature {
  margin-left: 25%;
  width: 50%;
}

.row-border {
  border-bottom: #000 1px solid;
}

.column-4 {
  display: grid;
  grid-template-columns: 10% 60% 15% 15%;
}

.column-2 {
  display: grid;
  grid-template-columns: 50% 50%;
}

.column-2-uf-city {
  display: grid;
  grid-template-columns: 80% 20%;
}

.col-border-right {
  border-right: #000 1px solid;
}

.ml-col {
  margin-left: 2px;
}
.mr-col {
  margin-right: 2px;
}
