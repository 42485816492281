.consult-page {
  .header {
    width: auto;
    position: initial;
    margin-bottom: 40px;
  }

  .title {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .scroll-card {
    max-height: 400px;
  }

  .line {
    width: auto;
    height: 1px;
    background-color: rgba(#6d6d6d, 0.8);
    margin-bottom: 30px;
    margin-left: -11px;
    margin-right: -11px;
  }

  .card {
    // width: 1100px;
    // height: 650px;
    // align-self: center;
    box-shadow: none;
    background-color: #fafbfe;
    align-content: center;
    margin: auto;
    padding: 10px 20px;
  }

  @media (max-width: 1200px) {
    .footer {
      bottom: initial;
    }
  }

  .box {
    box-shadow: $box-shadow;
    background-color: #fff;
    padding: 10px 10px;
  }

  .footer {
    right: initial;
    left: initial;
    width: 100%;
    text-align: center;
  }
}
