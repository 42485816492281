//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

.btn-return {
  margin: 20px 0 20px 0;
}

.ft-size-1 {
  font-size: 1.25em;
}

.ft-size-2 {
  font-size: 2em;
}

.text-green {
  color: $color-default-2 !important;
}

.mt-3-larger {
  margin-top: 1.7rem !important;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

.box-time {
  padding: 10px 10px 5px 10px;
  margin: 10px 0 15px 0;
  background: #f6f7fb;
}

.btn-icon {
  padding: 2px;
  i {
    font-size: 1.3em;
  }
}

input {
  font-size: 11px !important;
}
