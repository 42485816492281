//
// Saira fonts
//

// Saira-Light
@font-face {
  font-family: 'Saira';
  src:
    local('Saira Light'),
    local('Saira-Light'),
    url('../../../fonts/saira/Saira-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

// Saira-Regular
@font-face {
  font-family: 'Saira';
  src:
    local('Saira Regular'),
    local('Saira-Regular'),
    url('../../../fonts/saira/Saira-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

// Saira-Medium
@font-face {
  font-family: 'Saira';
  src:
    local('Saira Medium'),
    local('Saira-Medium'),
    url('../../../fonts/saira/Saira-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

// Saira-SemiBold
@font-face {
  font-family: 'Saira';
  src:
    local('Saira SemiBold'),
    local('Saira-SemiBold'),
    url('../../../fonts/saira/Saira-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

// Saira-Bold
@font-face {
  font-family: 'Saira';
  src:
    local('Saira Bold'),
    local('Saira-Bold'),
    url('../../../fonts/saira/Saira-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
