//
// modal.scss
//

// Title text within header
.modal-title {
  margin-top: 0;
}

// Modal full width
.modal-full-width {
  width: 95%;
  max-width: none;
}

// Modal Positions
.modal-top {
  margin: 0 auto;
}

// Right Modal
.modal-right {
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  margin: 0;
  background-color: $modal-content-bg;
  align-content: center;
  transform: translate(25%, 0) !important;

  button.btn-close {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1;
  }
}

.modal {
  &.show {
    .modal-right,
    .modal-left {
      transform: translate(0, 0) !important;
    }
  }
}

// Bottom modal
.modal-bottom {
  display: flex;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  align-content: center;
}

// Colored modal header
.modal-colored-header {
  color: $white;
  padding: 6px 18px;
  border-radius: 0;

  .btn-close {
    font-weight: 600;
    margin-right: -15px;
    opacity: 1;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%)
      hue-rotate(339deg) brightness(101%) contrast(100%);
  }
}

.modal-colored-header.bg-primary {
  background: #004d59 !important;
}

// Full filled modals
.modal-filled {
  color: $white;

  .modal-header {
    background-color: rgba($white, 0.07);
  }

  .modal-header,
  .modal-footer {
    border: none;
  }

  .btn-close {
    color: $white !important;
  }
}

// Model
.modal-open {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.modal-update-status {
  background: #f1f3fa;
  color: #004d59;

  p {
    font-size: 12px;
    font-weight: 600;
  }
}

.modal-manage-pudo {
  .form-group {
    margin-bottom: unset !important;
  }
}

@media screen and (max-width: 992px) {
  .modal-manage-pudo {
    margin: 20px auto;
    max-width: 90%;
  }
}
